import React from 'react';

export default function Home() {
  return(
    <section className='home-main-container'>
      <h1>Welcome to our Wedding Site!</h1>
      <br/>
      <p className='welcome-text'>Hello! Thank you for visiting our site. We wanted to try and make it easy to communicate everything to propsective guests rather than try to fit it on the invitations (Ryan is a wordy person...he's working on it).
      <br/>
      <br/>
      We cannot wait to see everyone on May 15th...this has been a long time in the making and we are excited to share our day with all who can attend. Below are a few preview pictures from the venue. Hope to see you soon!
      </p>
      <section className='image-container'>
        <figure>
          <img src={ require('../assets/worsell-manor-welcome.jpg').default } alt={'Worsell Manor'}></img>
          <figcaption>Worsell Manor</figcaption>
        </figure>
        <figure>
          <img src={ require('../assets/IMG_7799-1.jpg').default } alt={'The Barn'}></img>
          <figcaption>The Barn</figcaption>
        </figure>
        <figure>
          <img src={ require('../assets/Barn-interior-01_resized.jpg').default } alt={'Inside the Barn'}></img>
          <figcaption>Inside the Barn</figcaption>
        </figure>
      </section>
    </section>
  )

}
