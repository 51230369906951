import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import Home from './Home';
import RSVP from './RSVPForm';
import Info from './Info';
import OurStory from './OurStory';
import Grateful from './Grateful';

function App() {

  return (
    <div className="App">
      <header className="top-bar">
        <nav className='nav-menu'>
          <Link to='/' className='nav-link'>Home</Link>
          <Link to='/rsvp' className='nav-link'>RSVP</Link>
          <Link to='/info' className='nav-link'>Wedding Info</Link>
          <Link to='/about' className='nav-link'>Our Story</Link>
          <Link to='/grateful' className='nav-link'>Gifts/Registry</Link>
        </nav>
      </header>
      <main className='main-section'>
        <Switch>
          <Route path='/grateful'>
            <Grateful/>
          </Route>
          <Route path='/about'>
            <OurStory/>
          </Route>
          <Route path='/info'>
            <Info/>
          </Route>
          <Route path='/rsvp'>
            <RSVP/>
          </Route>
          <Route path='/' component = { Home }/>
        </Switch>
      </main>
    </div>
  );
}

export default App;
