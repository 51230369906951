import React from 'react';

export default function Info() {
  return(
    <section className='info-section'>
      <h2>Need to Knows</h2>
      
      <p className='info-caps'>ceremony begins at 3:30 pm</p>
      <p className='info-caps'>cocktail hour 4-5pm, reception 5-9pm with photobooth</p>
      <p className='info'>Buffet style dinner with vegetarian options available</p>
      <p className='info'>Rain or shine!</p>
      <p className='info'>RSVP by April 24th</p>
      <br></br>
      <p className='info-caps'>hotel suggestions:</p>

      <section id='hotel-info'>
        <section id='hotel-links'>
          <div className='hotel-image-container'>
            <a 
              href='https://www.ihg.com/holidayinnexpress/hotels/us/en/middletown/ilgmt/hoteldetail'
              className='hotel-contact-info'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={ require('../assets/holiday_inn_middletown.jpg').default }
                alt={ 'Holiday Inn Middletown' }
                className='hotel-image'
              ></img>
              <figcaption>Holiday Inn Middletown</figcaption>
            </a>
            <a href='tel:877-859-5095' className='hotel-contact-info'>877-859-5095</a>
          </div>
          <div className='hotel-image-container'>
            <a
              href='https://www.hilton.com/en/hotels/mletnhx-hampton-middletown/'
              class='hotel-contact-info'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={ require('../assets/hampton_inn_middletown.jpg').default } alt={ 'Hampton Inn Middletown' } className='hotel-image'></img>
              <figcaption>Hampton Inn Middletown</figcaption>
            </a>
            <a href='tel:855-605-0317' className='hotel-contact-info'>855-605-0317</a>
          </div>
        </section>
        <p className='info'>Both hotels are in the west Middletown area approximately 15 minutes from the venue. We did not reserve a wedding rate block at either because the rates offered were higher than the normal listed rate on the site. We have stayed in neither hotel, but can say that the Holiday Inn is several years newer and near a couple casual dining restaurants. There is considerable parking at the venue that should be able to accommodate all guests. If you have additional questions about travel logistics, please let us know in the additional info section of the RSVP.</p>
      </section>

      <p className='info'>Social distancing and masking guidelines will be observed in compliance with Maryland state mandates at the time of the wedding.</p>
      <p className='info'>No guests under 18, please</p>
      <h3>We can't wait to celebrate with you!</h3>
    </section>
  )
}