import * as yup from 'yup';

export default yup.object().shape({
  name: yup
    .string()
    .required('Please enter your name')
    .min(7, 'The name must be at least 7 characters long'),
  response: yup
    .string()
    .required('Please let us know if you can make it to the wedding'),
  guests: yup.string(),
  email: yup.string().email().notRequired(),
  message: yup.string()
})