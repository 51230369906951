import React from 'react';

export default function Grateful() {

  return(
    <section className='gifts-main-container'>
      <h2>Gifts/Registry</h2>
      <p className='gift-request'>Life has been so different, challenging, and eventful since March 2020 that we feel incredibly grateful to finally just get to have this wedding. We also recognize that it has been even more difficult and challenging for a great many. We feel lucky and blessed to already have most of what we need. <br/> <br/>
      We kindly request you consider a monetary gift. We are hoping to expand the size of the Hammer brood as 2021 turns into 2022. While any gift (or none at all!) will be greatly appreciated, all money we receive will be put toward building a nest egg for when we add to our nest. A spot will be available to deposit flat gifts at the venue, and we will post a method for collecting money digitally on this page as the event draws closer <br/> <br/>
      In any event, we are just happy to finally throw this party and host everyone in the safest and most celebratory way possible. Thank you so much and we look forward to seeing you soon!</p>
    </section>
  )
}