

export const initialFormValues = {
  // Text Input for name
  name: '',
  // Dropdown Input for Attendance
  response: '',
  // Text Input for Guest Names
  guests: '',
  // Text Input for Email
  email: '',
  // Text Input for additional message
  message: ''
};

export const initialFormErrors = {
  // Setting intial values for form validation error messages
  name: '',
  response: '',
  email: ''
};