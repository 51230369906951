import React, { useState, useEffect } from 'react';
import emailjs, { init } from 'emailjs-com';
import { initialFormValues, initialFormErrors } from './FormExports';
import * as yup from 'yup';
import schema from './FormSchema';
import ToastMaker from 'toastmaker';
import "toastmaker/dist/toastmaker.css";

export default function RSVP() {

  init("user_UTVWJrR46zT0KGvPOCpu7");

  // Setting form values object into state
  const [formValues, setFormValues] = useState(initialFormValues);
  // Setting object containing different form field errors into state
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  // Setting boolean variable controlling submit button into state
  const [disabled, setDisabled] = useState(true);

  const sendEmailFunction = (responses) => {
    const templateID = 'template_z2c8c9e';
  
    const templateParams = {
      from_name: responses.name,
      response: responses.response,
      guests: responses.guests,
      email: responses.email,
      message: responses.message
    };

    emailjs
      .send('default_service', templateID, templateParams)
        .then((res) => {
          ToastMaker('Your form response was sent. Thank you for the response!');
          console.log(res.text);
          setFormValues(initialFormValues);
          setFormErrors(initialFormErrors);
        }, (error) => {
          ToastMaker('On no! Something went wrong with form submission');
          console.log(error.text);
        });
  }

  const formSubmit = (values, sendFunction) => {
    const newResponse = {
      name: values.name.trim(),
      response: values.response,
      guests: values.guests.trim(),
      email: values.email.trim(),
      message: values.message.trim()
    };
    sendFunction(newResponse);
  }

  const onSubmit = evt => {
    evt.preventDefault();
    formSubmit(formValues, sendEmailFunction);
  }

  const onCancel = evt => {
    setFormValues(initialFormValues);
    setFormErrors(initialFormErrors);
  }

  // Event handler functions
  const inputChange = (name, value) => {
    // Using yup.reach to use the defined schema to test individual parts of the form for validation
    // It takes the schema as the first argument, and the key to be tested as the second argument
    yup
      .reach(schema, name) 
      .validate(value)
      // Clears error if validation is successful
      .then(() => {
        setFormErrors({
          ...formErrors,
          [name]: '',
        });
      })
      .catch((err) => {
        setFormErrors({
          ...formErrors,
          // Validation error from schema if unsuccessful
          [name]: err.errors[0],
        });
      })
    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const onChange = evt => {
    const { name, value } = evt.target;
    inputChange(name, value);
  }

    // Employing an effect hook to validate the form each time it is updated allowing for the submit button to be enabled once the form is completely valid
    useEffect(() => {
      schema.isValid(formValues).then(valid => {
        setDisabled(!valid);
      });
    }, [formValues])

  return(
    <section className='rsvp-main-container'>
      <h2>Let Us Know If You Can Make It!!</h2>
      <form onSubmit={onSubmit}>
        <fieldset>
          <label className='form-label'>Name:
            <input
              value={formValues.name}
              onChange={onChange}
              name='name'
              type='text'
            />
          </label>

          <label className='form-label'>Please select a response:
            <select
              onChange={onChange}
              value={formValues.response}
              name='response'
            >
              <option value=''>- Select an option -</option>
              <option value='Accepts with pleasure'>Accepts with pleasure</option>
              <option value='Regretfully declines'>Regretfully declines</option>
            </select>
          </label>

          <label className='form-label'>Name(s) of Guest(s) Attending:
            <input
              value={formValues.guests}
              onChange={onChange}
              name='guests'
              type='text'
            />
          </label>

          <label className='form-label'>Email (optional):
            <input
              value={formValues.email}
              onChange={onChange}
              name='email'
              type='text'
            />
          </label>

          <label className='form-label'>Anything else you'd like us to know:
            <input id='big-input'
              value={formValues.message}
              onChange={onChange}
              name='message'
              type='text'
            />
          </label>
          
        </fieldset>

        <section className='errors-section'>
            <p>{formErrors.name}</p>
            <p>{formErrors.response}</p>
            <p>{formErrors.email}</p>
        </section>

        <div className='button-div'>
          <button disabled={disabled} id='submitBtn' >Click to RSVP!</button>
        </div>

      </form>
      <section className='button-div'>
        <button id='cancelBtn' onClick={onCancel}>Clear Form</button>
      </section>
      
    </section>
  )
}